<template>
  <div style="height: 100%;">
    <div class="scroll">
      <div class="page-no" v-if="isShow">
        <van-icon name="user-o" size="70" color="#999" />
        <p>您还没有权限</p>
      </div>
      <div class="scroll-center">
        <!-- 顶部 -->
        <div class="head">
          <div class="head-top">
            <div class="head-top-left">
              <h2>
                <i>{{ conf.symbol }}{{ info.pay | million | fillZero | priceInit }}</i>
                <span v-if="info.pay > 9999">万</span>
              </h2>
              <span class="s">{{ currentText }}总收入</span>
            </div>
            <!-- <div class="head-top-right">
              <div class="head-top-guanzhu">
                <span v-if="!info.concern" @click="fnClickGuanzhu">关注</span>
                <span class="yes" @click="fnClickUnGuanzhu" v-else>已关注</span>
              </div>
            </div> -->
          </div>
          <div class="head-list">
            <ul>
              <li class="t">
                <span>{{ currentText }}广告:</span>{{ conf.symbol }}{{ info.ad_amount| million | fillZero | priceInit }}
                <template v-if="info.ad_amount > 9999">万</template>
              </li>
              <li class="t">
                <span>{{ currentText }}内购:</span>{{ conf.symbol }}{{ info.pay_amount| million | fillZero | priceInit }}
                <template v-if="info.pay_amount > 9999">万</template>
              </li>
              <li class="t">
                <span>{{ currentText }}活跃:</span>{{ info.month_active_num | million | priceInit }}
                <template v-if="info.month_active_num > 9999">万</template>
              </li>
              <li>
                <span>{{ currentText }}新增:</span>{{ info.month_reg_num | priceInit }}
              </li>
              <li class="t">
                <span>本月上架剧:</span>
                {{ info.month_online_play}}

              </li>
              <li class="t">
                <span>上架剧总数:</span>
                {{ info.online_play   }}
              </li>
              <li>
                <span>累计收入:</span>
                {{ conf.symbol }}
                {{ info.income | million | fillZero | priceInit }}
                <template v-if="info.income > 9999">万</template>
              </li>
              <!-- <li class="t" v-if="dataAccess">
                <span>{{ currentText }}消耗:</span>
                {{ conf.symbol }}{{ info.month_cost | million | fillZero | priceInit
                }}<template v-if="info.month_cost > 9999">万</template>
              </li>
              <li v-if="dataAccess">
                <span>累计消耗:</span>
                {{ conf.symbol }}{{ info.aggregate_cost | million | fillZero | priceInit
                }}<template v-if="info.aggregate_cost > 9999">万</template>
              </li>
              <li class="t">
                <span>累计收入:</span>
                {{ conf.symbol }}{{ info.aggregate_pay | million | fillZero | priceInit
                }}<template v-if="info.aggregate_pay > 9999">万</template>
              </li> -->
            </ul>
          </div>
        </div>
        <!-- /顶部 -->
        <!-- 数据报表切换 -->
        <div class="tabs">
          <Tabs :tabs="tabs" :fnClickTabs="fnClickTabs" :fontSize="'0.32rem'" :lineWidth="'100%'"></Tabs>
        </div>
        <!-- /数据报表切换 -->
        <!-- 数据报表 -->
        <div class="data-list">
          <div class="data-list-tabs">
            <ul>
              <li :class="[dimension === 'day' ? 'cur' : '']" @click="fnClickYear('day')">日</li>
              <li :class="[dimension === 'month' ? 'cur' : '']" @click="fnClickYear('month')">月</li>
            </ul>
          </div>
          <EchartColumn :dataList="dataList"></EchartColumn>
          <div class="data-list-quanping" @click="fnFullScree">
            <em></em>
          </div>
        </div>
        <!-- /数据报表 -->
        <!-- 数据概览 -->
        <div class="data-overview" ref="dataScroll">
          <div ref="bottomColumn">
            <van-sticky>
              <div class="data-overview-top">
                <h2><em></em>数据概览</h2>
                <div class="data-overview-top-tabs">
                  <Tabs
                    :tabs="tabsOverview"
                    :fnClickTabs="fnClickOverviewTabs"
                    :fontSize="'0.28rem'"
                    :lineWidth="'100%'"
                  ></Tabs>
                </div>
              </div>
              <!-- 时间 -->
              <div class="data-overview-center">
                <div class="data-overview-center-list" v-if="!isEdit">
                  {{ startDate }}～{{ endDate }} <span v-show="isEditShow" @click="fnEdit">修改</span>
                </div>
                <!-- 修改自定义时间 -->
                <div class="data-overview-center-time" v-else>
                  <div class="data-overview-center-time-box">
                    <input v-model="startTime" readonly="readonly" @click="fnClickDate(0)" placeholder="开始日期" />
                    ~
                    <input v-model="endTime" readonly="readonly" @click="fnClickDate(1)" placeholder="结束日期" />
                  </div>
                  <van-button round type="info" color="#1577FF" size="mini" style="width: 48px" @click="fnClickConfirm">
                    确认
                  </van-button>
                </div>
              </div>
            </van-sticky>
          </div>
          <!-- /时间 -->
          <!-- 数据内容 -->
          <div class="data-overview-bottom" ref="dataTable" @scroll="fnScroll">
            <!-- 浮动表头 -->
            <div
              v-show="isHideShow"
              class="data-overview-bottom-column"
              :style="{
                position: 'fixed',
                width: tableWidth + 'px',
                top: topHeight + 'px',
                left: topLeft + 'px',
                transform: 'translateZ(0)',
                '-webkit-transform': 'translateZ(0)'
              }"
            >
              <div class="data-overview-bottom-column-span">
                <span v-for="(item, index) in column" :key="index" :class="[index === 0 ? 'l' : '']">{{
                  columnlist[item]
                }}</span>
              </div>
            </div>
            <!-- /浮动表头 -->
            <!-- 浮动左侧 -->
            <div
              class="data-overview-left"
              :class="[boxLeft ? 'boxshadow' : '']"
              :style="{
                position: 'fixed',
                top: leftTopFix + 'px',
                transform: 'translateZ(0)',
                '-webkit-transform': 'translateZ(0)'
              }"
              v-show="isLeftShow"
            >
              <div class="data-overview-left-top">
                <span class="l">{{ columnlist[column[0]] }}</span>
              </div>
              <div class="data-overview-bottom-box" :style="{ position: 'relative', top: topTop + 'px' }">
                <div
                  class="data-overview-bottom-box-list"
                  v-for="(item, index) in tableData"
                  :key="index"
                  :style="{ 'font-weight': index == tableData.length - 1 ? 'bold' : 'normal' }"
                >
                  <span class="l">
                    <template>{{ item[0] == 'total' ? '总计/平均' : item[0] }}</template>
                  </span>
                </div>
              </div>
            </div>
            <!-- /浮动左侧 -->
            <!-- 固定表头 -->
            <div class="data-overview-bottom-column g" ref="columnHeight" :style="{ width: tableWidth + 'px' }">
              <span v-for="(item, index) in column" :key="index" :class="[index === 0 ? 'l' : '']">
                {{ columnlist[item] }}
              </span>
            </div>
            <div class="data-overview-bottom-box" ref="bottomList" :style="{ width: tableWidth + 'px' }">
              <div
                class="data-overview-bottom-box-list"
                v-for="(item, index) in tableData"
                :key="index"
                :style="{ 'font-weight': index == tableData.length - 1 ? 'bold' : 'normal' }"
              >
                <span
                  v-for="(itemlist, itemlistindex) in item"
                  :key="itemlistindex"
                  :class="[itemlistindex === 0 ? 'l' : '']"
                >
                  <template v-if="itemlist !== null || itemlistindex === 0">
                    <!-- 处理加加钱符号数字 -->
                    <template v-if="columnIndexMoney.indexOf(itemlistindex) !== -1">
                      {{ conf.symbol }}{{ itemlist | million | fillZero | priceInit
                      }}<template v-if="itemlist > 9999">万</template>
                    </template>
                    <!-- 处理加%号数字 -->
                    <template v-else-if="columnIndex.indexOf(itemlistindex) !== -1"
                      >{{ itemlist | fillZero }}%</template
                    >
                    <!-- 特殊处理首列 -->
                    <template v-else-if="itemlistindex === 0">
                      {{ itemlist == 'total' ? '总计/平均' : itemlist }}
                    </template>
                    <!-- 处理不是金额类数字 -->
                    <template v-else>{{ itemlist | priceInit }}</template>
                  </template>
                  <template v-else>-</template>
                </span>
              </div>
            </div>
          </div>
          <!-- /数据内容 -->
        </div>
        <!-- /数据概览 -->
      </div>
      <div class="scroll-footer">
        <!-- 底部导航 -->
        <FooterNav></FooterNav>
      </div>
    </div>
    <!-- 日期选择 -->
    <van-popup v-model="show" position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :title="dateTitle"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="fnConfirm"
        @cancel="fnCancel"
      />
    </van-popup>
    <!-- /日期选择 -->
    <!-- 全屏 -->
    <div class="chart-full-box" v-show="showChartFull">
      <div class="chart-full-box-list">
        <EchartColumn
          :dataList="dataList"
          :width="screenHeight + 'px'"
          :height="screenWidth + 'px'"
          :id="'mainFull'"
        ></EchartColumn>
      </div>
      <div class="chart-full-box-close" @click="fnFullScree">
        <span></span>
      </div>
    </div>
    <!-- /全屏图表 -->
    <van-overlay :show="showLoading">
      <div class="vant-loading">
        <van-loading />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { setDocumentTitle } from '@/utils/domUtil'
import { Tabs, EchartColumn, FooterNav } from '@/components'
import { apiDataGamedetail, apiDataGamedetailoverview, apiDataGamedetailchart } from '@/api/topreels'
import { dataInit } from '@/api/home'
import { fnGameFocus, fnGameUnfocus } from '@/common/common'
import { million } from '@/utils/util'
import moment from 'moment'
export default {
  components: {
    Tabs,
    FooterNav,
    EchartColumn
  },
  data() {
    return {
      isHideTips: false, //隐藏没有权限的字段
      showLoading: false,
      screenWidth: null, // 屏幕宽
      screenHeight: null, // 屏幕高
      info: {
        game_type: 1, //1:页游 4:国内手游 5:海外手游
        pay: 0, //金额(万)
        relative_ratio: 0, //环比
        ad_amount: 0, //广告(万)
        pay_amount: 0, //内购(万)
        month_cost: 0, //月消耗(万)
        month_reg_num: 0, //月新增
        month_active_num: 0, //月活跃
        aggregate_cost: 0, //累计消耗(万)
        aggregate_pay: 0, //累计收入(万)
        concern: 0, //0:未关注1:已关注
        online_play: 0, // 上架剧总数
        month_online_play: 0, // 本月上架剧总数
        income: 0
      },
      gameId: null,
      currentDay: null,
      currentText: '本月',
      dataAccess: 0,
      hwDataAccess: 0,
      // 图表切换
      tabs: [],
      dimension: 'day',
      allDataIndex: [],
      allDataList: [],
      dataList: {},
      dataListFull: {},
      dataTabsType: 1,
      showChartFull: false,
      // 数据概览
      tabsOverview: [
        {
          name: '近7天',
          value: 1
        },
        {
          name: '近30天',
          value: 2
        },
        {
          name: '自定义',
          value: 3
        }
      ],
      timeType: 1,
      isEdit: false,
      isEditShow: false,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      show: false,
      dateTitle: null,
      minDate: new Date(),
      maxDate: new Date(),
      currentSelectDate: null,
      currentDate: null,
      topHeight: 0, // 固定高度
      topLeft: 0,
      topTop: 0,
      boxLeft: 0,
      leftTopFix: 0,
      isHideShow: false,
      isLeftShow: false,
      // 定义表头
      columnlist: {
        //新增人数、活跃人数、付费人数、内购收入、广告收入、目标收入、总收入、付费率、ARPU、ARPPU
        dimension: '日期',
        reg_num: '新增人数',
        login_num: '活跃人数',
        pay_user_num: '付费人数',
        pay_amount: '内购收入',
        target_amount: '目标收入',
        ad_amount: '广告收入',
        total_amount: '总收入',
        pay_ratio: '付费率',
        total_arpu: '总ARPU',
        total_arppu: '总ARPPU',
        ARPU: 'ARPU',
        ARPPU: 'ARPPU',
        retention_2_ratio: '次留',
        total_cost: '广告消耗',
        gross_profit: '毛利',
        cpi: 'CPI',
        pay_amount_new: '新用户付费',
        pay_amount_old: '老用户付费',
        pay_user: '付费人数',
        ad_income: '广告收入',
      },
      column: ['dimension', 'reg_num', 'login_num', 'pay_user_num', 'pay_amount', 'pay_ratio', 'total_arpu', 'ARPPU'],
      columnIndex: [], //需要显示%符号的列有毛利和消耗列
      columnIndexMoney: [], //需要显示钱符号的列有毛利和消耗列
      tableData: [],
      tableWidth: null,
      columnWidth: 90,
      // 钱的符号
      conf: {
        type: '',
        symbol: ''
      },
      isShow: false
    }
  },
  created() {
    setDocumentTitle(`TopReels`)
  },
  mounted() {
    this.gameId = '1030'
    this.$nextTick(() => {
      this.topHeight = this.$refs.bottomColumn.getBoundingClientRect().height // 浮动顶部的高度
      this.columnHeight = this.$refs.columnHeight.getBoundingClientRect().height // 表头的高度
      this.screenWidth = document.body.clientWidth
      this.screenHeight = document.body.clientHeight
      window.removeEventListener('scroll', this.handScroll, true)
      window.addEventListener('scroll', this.handScroll, true)
      this.fnInit()
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handScroll, true)
  },
  methods: {
    /**
     * 页面初始化
     */
    fnInit() {
      dataInit().then(data => {
        const error = data.error
        if (error === 1000) {
          const res = data.res
          if (res.topreelsaccess) {
            this.fnGetGameDetail()
            this.fnDataGamedetailChart()
            this.fnGamedetailOverview()
            this.isShow = false
          } else {
            this.isShow = true
          }
        }
      })
      // console.log('clientWidth', document.body.clientWidth, 'clientHeight', document.body.clientHeight)
      // console.log('screenWidth', window.screen.availWidth, 'screenHeight', window.screen.availHeight)
    },
    /**
     * 获取详情数据
     */
    fnGamedetailOverview() {
      const params = {
        game_id: this.gameId // 游戏ID
      }
      apiDataGamedetailoverview(params).then(data => {
        console.log('data', data)
        const error = data.error
        if (error === 1000) {
          const res = data.res
          this.info = res.info
          this.currentDay = res.current_day
          this.currentDay !== 1 ? (this.currentText = '本月') : (this.currentText = '上月')
          this.conf = res.conf
          //是否有毛利和消耗权限
          this.isHideTips = res.data_access
          this.dataAccess = res.data_access
          this.hwDataAccess = res.hw_data_access
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 点击日月切换
     */
    fnClickYear(t) {
      this.dimension = t
      this.fnDataGamedetailChart()
    },
    //计算最大值
    calMax(arr) {
      let max = 0
      arr.forEach(el => {
        el.forEach(el1 => {
          if (!(el1 === undefined || el1 === '')) {
            if (max < el1) {
              max = el1
            }
          }
        })
      })
      let maxint = Math.ceil(max) //不让最高的值超过最上面的刻度
      let maxval = maxint //让显示的刻度是整数
      return maxval
    },
    //计算最小值
    calMin(arr) {
      let min = 0
      arr.forEach(el => {
        el.forEach(el1 => {
          if (!(el1 === undefined || el1 === '')) {
            if (min > el1) {
              min = el1
            }
          }
        })
      })
      //let minint = Math.floor(min / 10)
      //let minval = minint * 10 //让显示的刻度是整数
      return Math.ceil(min)
    },
    /**
     * 获取图表详情
     */
    fnDataGamedetailChart() {
      const params = {
        game_id: this.gameId, //游戏ID
        dimension: this.dimension, //(string,require)- day/month
        data_type: this.dataTabsType //(number,normal) - 1:新增 2:活跃 3:收入 4:广告
      }
      this.showLoading = true
      apiDataGamedetailchart(params).then(data => {
        console.log('图表详情', data)
        this.showLoading = false
        const error = data.error
        if (error === 1000) {
          const res = data.res
          const tabsArray = [
            {
              name: '新增',
              value: 1
            },
            {
              name: '活跃',
              value: 2
            },
            {
              name: '收入',
              value: 3
            }
          ]
          if (!res.display_ad_column || !res.data_access) {
            tabsArray.splice(3, 1)
          }
          this.dataAccess = res.data_access
          this.hwDataAccess = res.hw_data_access
          this.tabs = tabsArray
          this.allDataList = [].concat(res.data)
          this.allDataIndex = [].concat(res.index)
          let yAxisArray = [] // y轴
          let seriesArray = [] // 数据显示
          let echartsType = [] // 纵坐标样式
          let echartsY = [] // 纵坐标显示以哪个维度
          // 图表控制上下左右边距
          const gridArray = {
            left: '5%',
            right: '5%',
            top: '15%',
            bottom: '20%',
            containLabel: true
          }
          const dataZoomArray = [
            {
              type: 'inside',
              xAxisIndex: [0],
              filterMode: 'filter',
              startValue: this.allDataList.length - 14 > 0 ? this.allDataList.length - 14 : 0,
              endValue: this.allDataList.length - 1
            }
          ]
          // x轴
          let xAxisArray = {
            type: 'category',
            data: []
          }
          // 标签
          let legendArray = {
            bottom: 10,
            itemWidth: 6,
            itemHeight: 6,
            height: 30,
            fonSize: 14,
            icon: 'circle',
            itemGap: 15,
            zlevel: 10,
            data: []
          }
          // 提示
          let tooltipArray = {
            trigger: 'axis'
          }
          // 画的坐标轴
          echartsY = [0, 1] // 画的坐标轴

          // 1:新增 2:活跃 3:收入 4:广告
          const _this = this
          switch (this.dataTabsType) {
            case 1:
              echartsType = ['bar', 'line'] // 线的类型
              if (_this.dimension === 'month') {
                echartsType.splice(1, 1)
              }
              break
            case 2:
              echartsType = ['bar', 'line'] // 线的类型
              break
            case 3:
              echartsType = ['bar', 'bar','bar', 'line'] // 线的类型
              echartsY = [0, 0,0,1] // 画的坐标轴
              break
            case 4:
              echartsType = ['bar', 'line'] // 线的类型
              break
          }
          // 组装图表X坐标轴
          for (let index = 0; index < echartsType.length; index++) {
            console.log(this.allDataIndex)
            const e = this.allDataIndex[index + 1]
            // 设置标签中文名
            console.log(this.columnlist[e])
            legendArray.data.push(this.columnlist[e])
            // 设置坐标轴serise的属性
            seriesArray.push({
              name: this.columnlist[e], // 名称
              type: echartsType[index], // 图表的类型
              yAxisIndex: echartsY[index], //设置根据哪一个标尺
              // 设置图表选择焦点后，其它虚化
              // emphasis: {
              //   focus: 'series'
              // },
              data: []
            })
            // 收入中前二列堆叠(内购收入、广告收入)
            if (this.dataTabsType === 3 && index < 3) {
              seriesArray[index].stack = '总览'
            }
            console.log('seriesArray', seriesArray)
          }
          // 组装给图表Y轴的数据
          for (let index = 0; index < this.allDataList.length; index++) {
            const e = this.allDataList[index]
            for (let t = 0; t <= echartsType.length; t++) {
              const m = e[t]
              if (!t) {
                xAxisArray.data.push(m)
              } else {
                seriesArray[t - 1].data.push(m)
              }
            }
          }
          // let Min1 = this.calMin([seriesArray[0].data])
          // let Max1 = this.calMax([seriesArray[0].data])
          // let Min2 = this.calMin([seriesArray[1].data])
          // let Max2 = this.calMax([seriesArray[1].data])
          yAxisArray = [
            {
              type: 'value',
              position: 'left',
              min: 0,
              scale: true,
              textStyle: {
                color: '#7a7a7a'
              }
            },
            {
              type: 'value',
              scale: true,
              min: 0,
              splitLine: {
                //网格线
                show: false
              },
              textStyle: {
                color: '#7a7a7a'
              },
              position: 'right'
            }
          ]
          switch (this.dataTabsType) {
            case 1:
              yAxisArray[0].axisLabel = {
                formatter(e) {
                  return million(e)
                }
              }
              yAxisArray[1].axisLabel = {
                formatter: '{value}%'
              }
              // 鼠标触发效果
              tooltipArray = {
                trigger: 'axis',
                formatter: function(params) {
                  let a = '<div>'
                  a += '<p>' + params[0].name + '</p>'
                  for (let index = 0; index < params.length; index++) {
                    const element = params[index]
                    let value = 0
                    if (element.seriesName === '新增人数') {
                      value = million(element.value)
                    } else {
                      value = element.value + '%'
                    }
                    a += '<p>' + element.marker + element.seriesName + '：' + value + '</p>'
                  }
                  return a
                }
              }
              if (this.dimension === 'month') {
                yAxisArray.splice(1, 1)
              }
              break
            case 2:
              yAxisArray[0].axisLabel = {
                formatter(e) {
                  return million(e)
                }
              }
              yAxisArray[1].axisLabel = {
                formatter: this.conf.symbol + '{value}'
              }
              // 鼠标触发效果
              tooltipArray = {
                trigger: 'axis',
                formatter: function(params) {
                  let a = '<div>'
                  a += '<p>' + params[0].name + '</p>'
                  for (let index = 0; index < params.length; index++) {
                    const element = params[index]
                    let value = 0
                    if (element.seriesName === '活跃人数') {
                      value = million(element.value)
                    } else {
                      value = _this.conf.symbol + element.value
                    }
                    a += '<p>' + element.marker + element.seriesName + '：' + value + '</p>'
                  }
                  a += '</div>'
                  return a
                }
              }
              break
            case 3:
              yAxisArray[0].axisLabel = {
                formatter(e) {
                  return _this.conf.symbol + million(e)
                }
              }
              // 鼠标触发效果
              tooltipArray = {
                trigger: 'axis',
                formatter: function(params) {
                  console.log(params)
                  let allNum = 0
                  let a = '<div>'
                  a += '<p>' + params[0].name + '</p>'
                  // 计算总收入
                  for (let index = 0; index < params.length; index++) {
                    const element = params[index]
                    if (element.seriesName == '新用户付费' || element.seriesName == '老用户付费'|| element.seriesName == '广告收入') {
                      allNum += params[index].value
                    }
                  }
                  if (allNum > 0) {
                    a += '<p>总收入：' + _this.conf.symbol + million(allNum, 2) + '<p>'
                  }
                  for (let index = 0; index < params.length; index++) {
                    const element = params[index]
                    console.log(element.seriesName)
                    if (element.seriesName !== '付费人数') {
                      a +=
                        '<p>' +
                        element.marker +
                        element.seriesName +
                        '：' +
                        _this.conf.symbol +
                        million(element.value, 2) +
                        '</p>'
                    } else {
                      a += '<p>' + element.marker + element.seriesName + '：' + element.value + '</p>'
                    }
                  }
                  a += '</div>'
                  return a
                }
              }
              break
            case 4:
              yAxisArray[0].axisLabel = {
                formatter(e) {
                  return _this.conf.symbol + million(e)
                }
              }
              yAxisArray[1].axisLabel = {
                formatter(e) {
                  return _this.conf.symbol + million(e)
                }
              }
              // 鼠标触发效果
              tooltipArray = {
                trigger: 'axis',
                formatter: function(params) {
                  let a = '<div>'
                  a += '<p>' + params[0].name + '</p>'
                  for (let index = 0; index < params.length; index++) {
                    const element = params[index]
                    let value = 0
                    if (element.seriesName === '广告消耗') {
                      value = _this.conf.symbol + million(element.value)
                    } else {
                      value = _this.conf.symbol + element.value
                    }
                    a += '<p>' + element.marker + element.seriesName + '：' + value + '</p>'
                  }
                  a += '</div>'
                  return a
                }
              }
              break
            default:
              break
          }
          this.dataList = {
            legendArray,
            xAxisArray,
            yAxisArray,
            seriesArray,
            tooltipArray,
            gridArray,
            dataZoomArray
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 获取数据概览数据
     */
    fnGetGameDetail() {
      const params = {
        game_id: this.gameId,
        time_type: this.timeType, //(number,normal) - 1:近7天2:近30天3:自定义
        start_date: this.startTime, //(number,normal) - 数据查询开始日期
        end_date: this.endTime //(number,normal) - 数据查询结束日期
      }
      apiDataGamedetail(params).then(data => {
        console.log('data', data)
        const error = data.error
        if (error === 1000) {
          const res = data.res
          this.column = res.index
          this.startDate = res.start_date
          this.endDate = res.end_date
          this.startTime = this.startDate
          this.endTime = this.endDate
          // 获取表头后，计算表格宽度
          this.tableWidth = this.column.length * this.columnWidth
          //需要补零操作
          const dataArray = res.data
          dataArray.push(res.data_summary)
          this.tableData = dataArray
          //是否有毛利和消耗权限
          this.isHideTips = res.data_access
          this.dataAccess = res.data_access
          this.hwDataAccess = res.hw_data_access
          if (!this.isHideTips) {
            this.columnIndex = [5] //需要显示%符号的列没有毛利和消耗列
            this.columnIndexMoney = [4, 6, 7] //需要显示钱符号的列没有毛利和消耗列
          } else {
            //判断是否有毛利白名单
            if (this.hwDataAccess) {
              this.columnIndex = [5] //需要显示%符号的列有毛利和消耗列
              this.columnIndexMoney = [4, 6, 7] //需要显示钱符号的列有毛利和消耗列
            } else {
              this.columnIndex = [5] //需要显示%符号的列有毛利和消耗列
              this.columnIndexMoney = [4, 6, 7] //需要显示钱符号的列有毛利和消耗列
            }
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 数据概览点击切换
     */
    fnClickOverviewTabs(option) {
      console.log(option)
      const item = option.item
      this.timeType = item.value
      this.startTime = this.startDate
      this.endTime = this.endDate
      this.isEdit = false
      this.isEditShow = false
      switch (item.value) {
        case 1:
          this.fnGetGameDetail()
          break
        case 2:
          this.fnGetGameDetail()
          break
        case 3:
          this.isEdit = true
          this.isEditShow = true
          break
      }
    },
    /**
     * 日期选择点击
     */
    fnClickDate(option) {
      const preDay = moment()
        .subtract(1, 'days')
        .format('YYYY/MM/DD')
      if (!option) {
        this.dateTitle = '选择开始日期'
        this.minDate = new Date('2018/01/01')
        this.maxDate = new Date(preDay)
        this.currentDate = new Date(this.startTime)
      } else {
        this.dateTitle = '选择结束日期'
        const currentTime = new Date(this.startTime)
        const day90 = currentTime.setMonth(currentTime.getMonth() + 2) // 获取3个月后的时间
        this.minDate = new Date(this.startTime) // 设置日期最小值
        if (new Date(day90).getTime() > new Date().getTime()) {
          this.maxDate = new Date(preDay) // 设置日期最大值
        } else {
          this.maxDate = new Date(day90) // 设置日期最大值
        }

        this.currentDate = new Date(this.endTime)
      }
      this.currentSelectDate = option
      this.show = true
    },
    /**
     * 选择日期确认
     */
    fnConfirm(value) {
      const time = moment(value).format('YYYY/MM/DD')
      if (!this.currentSelectDate) {
        this.startTime = time
      } else {
        this.endTime = time
      }
      this.show = false
    },
    /**
     * 点击自定义时间确认
     */
    fnClickConfirm() {
      const sDate = new Date(this.startTime).getTime()
      const eDate = new Date(this.endTime).getTime()
      this.startDate = this.startTime
      this.endDate = this.endTime
      if (sDate > eDate) {
        this.$toast.fail('开始时间不能大于结束时间')
        return
      }
      if (eDate - sDate > 89 * 86400000) {
        this.$toast.fail('自义时间不能超过3个月')
        return
      }
      this.isEdit = false
      this.fnGetGameDetail()
    },
    /**
     * 点击全屏
     */
    fnFullScree() {
      console.log('全屏点击')
      this.showChartFull = !this.showChartFull
    },
    /**
     * 选择日期确认
     */
    fnCancel() {
      this.show = false
    },
    /**
     * 编辑
     */
    fnEdit() {
      this.isEdit = true
    },
    /**
     * tabs切换
     */
    fnClickTabs(option) {
      console.log(option)
      this.dataTabsType = option.item.value
      this.fnDataGamedetailChart()
    },
    /**
     * 关注
     */
    fnClickGuanzhu() {
      const params = {
        game_id: this.gameId
      }
      fnGameFocus(
        params,
        () => {
          this.info.concern = 1
        },
        msg => {
          this.$toast.fail(msg)
        }
      )
    },
    /**
     * 取消关注
     */
    fnClickUnGuanzhu() {
      const params = {
        game_id: this.gameId
      }
      fnGameUnfocus(
        params,
        () => {
          this.info.concern = 0
        },
        msg => {
          this.$toast.fail(msg)
        }
      )
    },
    /**
     * 表格左右滑动
     */
    fnScroll(e) {
      // 判断是否已经左滑
      this.boxLeft = e.target.scrollLeft
      if (this.boxLeft > 0) {
        this.isLeftShow = true // 显示左列表浮动
        this.topLeft = -e.target.scrollLeft // 设置左浮动列的x轴位置
      } else {
        this.isLeftShow = false // 隐藏左列浮动
        this.topLeft = 0
      }
    },
    /**
     * 监听滚动条滚动
     */
    handScroll() {
      // 在节点挂载之后，获取dom元素
      const toTop = this.$refs.dataScroll.getBoundingClientRect().top // 实时获取概览距离顶部位置，用于判断是否需要将表格头固定和切换时间栏固定
      const bottomListTop = this.$refs.bottomList.getBoundingClientRect().top // 实时获取表格列表的位置，用于计算左列浮动时的偏移量
      // 判断是否需要固顶
      if (toTop <= 0) {
        this.isHideShow = true // 显示固顶表头
        this.leftTopFix = this.topHeight // 设置浮动列距离顶部位置
      } else {
        this.isHideShow = false // 隐藏固顶表头
        // 先判断表格是否已经左滑，左滑就不在隐藏左列浮动
        if (this.topLeft === 0) {
          this.isLeftShow = false // 隐藏左列浮动
        }
        this.leftTopFix = bottomListTop - this.columnHeight // 动态设置浮动列距离顶部位置
      }
      // 判断滚动条是否已经到达数据表格的内容位置，需要动态设置滚动左列浮动的内容（滚动的位置+固定的高度+表头的高度>数据表格top的位置）
      if (bottomListTop <= this.topHeight + this.columnHeight) {
        this.topTop = bottomListTop - (this.topHeight + this.columnHeight)
      } else {
        this.topTop = 0
      }
      console.log(this.topTop)
    }
  }
}
</script>

<style lang="less" scoped>
.scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  .scroll-top {
    height: ((116+48) / @num);
  }
  .scroll-center {
    flex: 1;
    overflow: auto;
  }
  .scroll-footer {
    height: (116 / @num);
  }
}
.head {
  padding-bottom: (40 / @num);
  background: #fff;
  .head-top {
    display: flex;
    font-size: @font-24;
    padding: (22 / @num) (32 / @num) (10 / @num) (32 / @num);
    h2 {
      font-weight: normal;
    }
    .head-top-left {
      color: @font-2a2a2a;
      padding-right: (20 / @num);
      display: flex;
      align-items: flex-end;
      .s {
        padding-left: (15 / @num);
        line-height: (30 / @num);
        color: @font-7a7a7a;
        font-size: @font-28;
      }
      &.h {
        color: @font-ea5656;
      }
      &.r {
        color: @font-59a252;
      }
      h2 {
        font-size: @font-44;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-end;
        i {
          line-height: (38 / @num);
          font-style: normal;
        }
        span {
          font-size: (24 / @num);
          line-height: (28 / @num);
          padding-left: (8 / @num);
        }
      }
      p {
        padding-top: (10 / @num);
      }
    }
    .head-top-right {
      position: relative;
      color: @font-7a7a7a;
      font-size: @font-28;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      h2 {
        font-size: @font-28;
        padding-top: (10 / @num);
        padding-bottom: (10 / @num);
      }
      p {
        span {
          display: inline-block;
          i {
            font-style: normal;
            color: @font-232323;
          }
          &.r {
            padding-right: (10 / @num);
          }
        }
      }
      .head-top-guanzhu {
        width: (116 / @num);
        height: (40 / @num);
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          color: @paramcolor;
          font-size: @font-28;
          text-align: center;
          border-radius: (28 / @num);
          border: (2 / @num) solid @paramcolor;
          &.yes {
            color: @font-a3a3a3;
            border-color: @font-a3a3a3;
          }
        }
      }
    }
  }
}
.tabs {
  padding: 0 (40 / @num);
  margin-top: (2 / @num);
  height: (80 / @num);
  background: #fff;
}
.head-list {
  font-size: (28 / @num);
  padding: 0 (32 / @num);
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-top: (24 / @num);
      width: 50%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: @font-7a7a7a;
        display: block;
      }
      &.t {
        padding-right: (40 / @num);
      }
    }
  }
}
.data-list {
  position: relative;
  height: (498 / @num);
  background: #fff;
  margin: (2 / @num) 0;
  .data-list-tabs {
    height: (48 / @num);
    ul {
      display: flex;
      align-items: center;
      float: right;
      margin: (10 / @num) (20 / @num) 0 0;
      li {
        font-size: @font-28;
        color: @font-a3a3a3;
        width: (72 / @num);
        height: (48 / @num);
        text-align: center;
        line-height: (48 / @num);
        border-radius: (8 / @num) 0px 0px (8 / @num);
        border: (2 / @num) solid #f2f9ff;
        &.cur {
          color: @paramcolor;
          background: #f2f9ff;
        }
      }
    }
  }
  .data-list-quanping {
    position: absolute;
    right: (38 / @num);
    bottom: (26 / @num);
    width: (28 / @num);
    height: (28 / @num);
    em {
      display: block;
      width: 100%;
      height: 100%;
      background: url('../../assets/quanping3@2x.png') no-repeat;
      background-size: 100%;
    }
  }
}
.chart-full-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background: #fff;
  .chart-full-box-list {
    height: (750 / @num);
    transform: rotate(90deg);
  }
  .chart-full-box-close {
    position: absolute;
    right: (20 / @num);
    bottom: (20 / @num);
    width: (52 / @num);
    height: (52 / @num);
    span {
      display: block;
      width: 100%;
      height: 100%;
      background: url('../../assets/close@2x.png') no-repeat;
      background-size: 100%;
    }
  }
}

.data-overview {
  position: relative;
  .data-overview-top {
    height: (156 / @num);
    background: #fff;
    padding: 0 (32 / @num);
    h2 {
      font-size: @font-32;
      display: flex;
      align-items: center;
      color: @font-2F2F2F;
      padding-top: (20 / @num);
      em {
        width: (8 / @num);
        height: (32 / @num);
        background: @paramcolor;
        border-radius: (4 / @num);
        margin-right: (16 / @num);
      }
    }
    .data-overview-top-tabs {
      margin-left: -(26 / @num);
      width: (360 / @num);
      height: (80 / @num);
    }
  }
  .data-overview-center {
    height: (64 / @num);
    background: #f5f5f5;
    color: @font-a3a3a3;
    .data-overview-center-list {
      color: @font-a3a3a3;
      display: flex;
      align-items: center;
      font-size: @font-24;
      height: 100%;
      padding-left: (32 / @num);
      padding-right: (32 / @num);
      span {
        display: block;
        margin-left: (20 / @num);
        color: @paramcolor;
      }
    }

    .data-overview-center-time {
      display: flex;
      align-items: center;
      padding-top: (8 / @num);
      margin-left: (32 / @num);
      .data-overview-center-time-box {
        display: flex;
        align-items: center;
        font-size: @font-24;
        width: (526 / @num);
        height: (48 / @num);
        background: #ffffff;
        border-radius: (24 / @num);
        border: (2 / @num) solid #c6c8cc;
        margin-right: (32 / @num);
        padding-left: (32 / @num);
        input {
          width: (140 / @num);
          margin: 0 (48 / @num);
          text-align: center;
          color: @font-a3a3a3;
          font-size: @font-24;
          border: none;
        }
      }
    }
  }
  .data-overview-bottom {
    position: relative;
    background: #fff;
    padding-bottom: (20 / @num);
    width: 100%;
    overflow: auto;
    table {
      th {
        width: (180 / @num);
        font-size: @font-28;
        color: @font-7a7a7a;
        font-weight: normal;
        height: (52 / @num);
        padding: (10 / @num);
      }
      td {
        padding: (10 / @num);
        font-size: @font-28;
        text-align: center;
      }
    }
  }
}

.data-overview-bottom-column,
.data-overview-left-top {
  display: flex;
  align-items: center;
  justify-content: center;
  background: @font-E8E8E8;
  height: 30px;
  span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 90px;
    font-size: (28 / @num);
    font-weight: normal;
    height: 100%;
    text-align: right;
    color: @font-2F2F2F;
    &.l {
      padding-left: (32 / @num);
      width: (200 / @num);
      justify-content: flex-start;
      text-align: left;
    }
  }
}
.data-overview-bottom-column {
  &.g {
    padding-right: (20 / @num);
  }
}
.data-overview-bottom-column-span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  padding-right: (20 / @num);
}
.data-overview-left-top {
  position: relative;
  z-index: 4;
  padding-right: (20 / @num);
}
.data-overview-left {
  background: #fff;
  &.boxshadow {
    box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.12);
  }
}
.data-overview-bottom-box-list {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  padding-right: (20 / @num);
  height: (80 / @num);
  border-bottom: (2 / @num) solid @font-F7F7F7;
  &.b {
    background: #eee;
  }
  span {
    display: block;
    width: 90px;
    height: 25px;
    line-height: 25px;
    font-size: (28 / @num);
    text-align: right;
    &.l {
      padding-left: (32 / @num);
      width: (200 / @num);
      text-align: left;
    }
  }
}

.page-no {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  p {
    padding-top: (10 / @num);
    font-size: @font-32;
    color: @font-a3a3a3;
  }
}
</style>
